






































import { Component, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';

import AppVue from '@/AppVue.vue';
import { InputDateCpt, InputSelectCpt } from '@/core/components';
import {
  ReporterAccessRequestLookupModel,
  ReporterAccessRequestModel,
  UserAccountLookupModel,
  UserAccountModel,
  UserReporterOrganisationModel,
  UserReporterStudyModel,
} from '@/core/webapi';
import { StudyTypes } from '@/core/constants';
import { ReportLookupService } from '@/core/services';

@Component({
  components: {
    InputDateCpt,
    InputSelectCpt,
  },
})
export default class InputOrganisationStudiesCpt extends AppVue {
  @Prop() rootModel: UserAccountModel | ReporterAccessRequestModel; // Needed for modelState / model validation
  @Prop() model: UserReporterOrganisationModel;
  @Prop() lookups: UserAccountLookupModel | ReporterAccessRequestLookupModel;
  @Prop({ default: false }) disabled: boolean;
  @Prop() orgIndex: number;

  internalTimestamp = new Date().toString();

  // Internal model is needed because the Element SELECT component works with an array
  // of IDs, but we need to work with an array of objects
  internalModel = {
    studies: [] as number[],
    modelState: {} as any,
  };
  eliteId = StudyTypes.Elite;
  communityId = StudyTypes.Community;

  // NOTE: Using this nonsense hack to force refresh on modelState errors because this.$forceUpdate() is not enough here
  get timestamp() {
    return this.internalTimestamp;
  }

  get studies() {
    if (!this.model?.organisationId) {
      return [];
    }

    const organisation = this.lookups.organisations?.find(p => p.id === this.model.organisationId);
    return organisation
      ? ReportLookupService.filterOrgStudies(this.lookups, organisation.id, organisation.studyTypeId)
      : [];
  }

  get studiesPlaceholder() {
    if (!this.studies.length) {
      return this.$t('No studies available for this organisation');
    }

    if (!this.disabled) {
      return this.$t('Please select studies');
    }

    if (this.disabled && this.model.studies && !this.model.studies.length) {
      return this.$t('No studies selected');
    }

    return '';
  }

  @Watch('rootModel.modelState', { deep: true, immediate: true })
  onModelStateChanged(val: any, oldVal: any) {
    if (!this.model.studies) {
      return;
    }

    this.model.studies.forEach((study: any, index: number) => {
      study.modelState = {};

      const fromKey = this.getValidFromValidationKey(index);
      if (fromKey && val && val[fromKey]) {
        study.modelState.validFrom = val[fromKey];
      }

      const toKey = this.getValidToValidationKey(index);
      if (toKey && val && val[toKey]) {
        study.modelState.validUntil = val[toKey];
      }
    });

    this.internalTimestamp = new Date().toString();
  }

  getValidFromValidationKey(studyIndex: number) {
    // The first option is for ReporterAccessRequestCpt.vue
    // The second option is for ReporterOrganisationAccessRequestView.vue
    return this.orgIndex >= 0
      ? `reporterOrganisations[${this.orgIndex}].Studies[${studyIndex}].ValidFrom`
      : `studies[${studyIndex}].ValidFrom`;
  }

  getValidToValidationKey(studyIndex: number) {
    // The first option is for ReporterAccessRequestCpt.vue
    // The second option is for ReporterOrganisationAccessRequestView.vue
    return this.orgIndex >= 0
      ? `reporterOrganisations[${this.orgIndex}].Studies[${studyIndex}].ValidUntil`
      : `studies[${studyIndex}].ValidUntil`;
  }

  created() {
    this.internalModel.studies = this.model.studies?.filter(p => !!p.studyId).map(p => p.studyId!) ?? [];
  }

  onStudiesChanged(selectedIds: number[]) {
    this.model.studies = this.model.studies?.filter(p => !!p.studyId && selectedIds.includes(p.studyId)) ?? [];

    selectedIds.forEach(selectedId => {
      if (!this.model.studies!.find(p => p.studyId === selectedId)) {
        const study = this.studies!.find(p => p.id === selectedId);

        if (!study) {
          return;
        }

        // Using unshift to keep the "inviteKey org" the first one in the list
        this.model.studies!.push({
          studyId: study.id,
          studyName: study.name,
          validFrom: study.startDate,
          validUntil: study.endDate,
          modelState: {},
        } as any);
      }
    });

    this.$forceUpdate();
  }

  getStudyFromTo(study: UserReporterStudyModel) {
    const lookupStudy = this.studies!.find(p => p.id === study.studyId);

    return !!lookupStudy
      ? `(${moment(lookupStudy.startDate).format('YYYY-MM-DD')} - ${moment(lookupStudy.endDate).format('YYYY-MM-DD')})`
      : '';
  }

  onDateChange() {
    this.internalTimestamp = new Date().toString();
    this.$forceUpdate();
  }
}
