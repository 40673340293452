







































































































































import { Component, Prop } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import { PlayerCollectionModel } from '@/core/webapi';
import { DetailsTableRowCpt } from '@/core/components';
import { ApiService } from '@/core/services';

@Component({
  components: {
    DetailsTableRowCpt,
  },
})
export default class PlayerDeduplicateCpt extends AppVue {
  @Prop({ default: false }) showDialog: boolean;
  @Prop() keepUserId: number;
  @Prop() onSuccess: () => void;

  players: PlayerCollectionModel[] = [];
  selectedPlayerId: number | null = null;
  playerToKeep: string | null = null;

  get isOpen() {
    return this.showDialog;
  }

  get player1() {
    return this.players?.find(p => p.id === this.keepUserId);
  }

  get player2() {
    if (!this.selectedPlayerId) {
      return null;
    }

    return this.players?.find(p => p.id === this.selectedPlayerId);
  }

  get otherPlayers() {
    return this.players?.filter(p => p.id !== this.player1?.id) ?? [];
  }

  async created() {
    this.players = (await ApiService.players().filterPlayers({ page: 1 } as any)).data.items || [];
  }

  onPlayerSelected(playerId: number) {
    this.selectedPlayerId = playerId;
  }

  async save() {
    if (!confirm(this.$t('Are you sure you want to merge and deduplicate these two players?') as string)) {
      return;
    }

    const keepPlayerId = this.playerToKeep === 'left' ? this.player1!.id : this.player2!.id;
    const deletePlayerId = this.playerToKeep === 'left' ? this.player2!.id : this.player1!.id;

    await ApiService.players().mergeAndDeduplicate({ keepPlayerId, deletePlayerId });

    this.$notify({
      title: this.$t('Success') as string,
      message: this.$t('Players merged and deduplicated') as string,
      type: 'success',
    });

    if (this.onSuccess) {
      this.onSuccess();
    }

    this.close(true);
  }

  close(refreshData = false) {
    this.selectedPlayerId = null;
    this.playerToKeep = null;
    this.$emit('close', refreshData);
  }
}
