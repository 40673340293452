













































import { Component, Prop } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import {
  UserAccountLookupModel,
  ReporterAccessRequestLookupModel,
  UserReporterOrganisationModel,
  ReporterAccessRequestOrganisationModel,
} from '@/core/webapi';
import { StudyTypes } from '@/core/constants';

@Component
export default class InputOrganisationPermissionsCpt extends AppVue {
  @Prop() organisation: UserReporterOrganisationModel | ReporterAccessRequestOrganisationModel;
  @Prop() lookups: UserAccountLookupModel | ReporterAccessRequestLookupModel;
  @Prop({ default: false }) disabled: boolean;
  @Prop() accessLevelLabel: string;

  eliteId = StudyTypes.Elite;
  communityId = StudyTypes.Community;

  get showCatastrophic() {
    return this.$route.name === 'adminUserNew' || this.$route.name === 'adminUserEdit';
  }

  get internalAccessLevelLabel() {
    if (this.accessLevelLabel) {
      return this.accessLevelLabel;
    }

    return this.$t('Access level within the organisation');
  }
}
